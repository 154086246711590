.leafletContainer {
    width: 100%;
    height: 85%;
    border-radius: 0.5rem;
}

.clusterIcon {
    background-color: #4a60a8;
    height: 2em;
    width: 2em;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 1.2rem;
    box-shadow: 0 0 0px 5px #3db4bd;
}
